import { atom } from "recoil"
import { MyOrganization } from "src/queries/getMyOrganizations"
import { getSelectedTeam, setSelectedTeam } from "src/storage/selectedTeam"

export const selectedMyOrganizationState = atom<MyOrganization["id"] | undefined>({
  key: "atoms/organization/selectedMyOrganizationState",
  default: getSelectedTeam(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => setSelectedTeam(newValue))
    },
  ],
})
