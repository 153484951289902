import dayjs from "dayjs"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import * as Clipboard from "expo-clipboard"
import {
  FlatList,
  Image,
  Keyboard,
  ListRenderItemInfo,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View,
} from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CancelWithCircleIcon } from "src/components/parts/icons/CancelWithCircleIcon"
import { CheckWithCircleIcon } from "src/components/parts/icons/CheckWithCircleIcon"
import { DraftIcon } from "src/components/parts/icons/drawer/DraftIcon"
import { NoPersonIcon } from "src/components/parts/icons/NoPersonIcon"
import { SendMessageIcon } from "src/components/parts/icons/SendMessageIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { ContactReplyThreadItem, ContactReplyThreadType } from "src/constants/ContactReplyThreadType"
import { headerHeight } from "src/constants/headerHeight"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useMarkThreadMessageAsRead } from "src/recoil/hooks/contact/useMarkThreadMessageAsRead"
import { useContactReplyThreadData } from "src/recoil/hooks/screens/useContactReplyThreadData"
import { RootStackScreenProps } from "src/types.d"
import { CustomAlert } from "src/utils/CustomAlert"
import { useFetcher } from "src/hooks/useFetcher"
import { Analytics } from "src/tags/analytics/Analytics"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { isEmptyString } from "src/utils/validate"
import { SeeMoreIcon } from "src/components/parts/icons/SeeMoreIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type ContactReplyThreadLinkParams = {
  replyThreadId: string
}

export type ContactReplyThreadParams = {
  replyThreadId: string
  isNew: boolean
  contactTitle?: string
  senderName?: string
}

type Props = RootStackScreenProps<typeof Screens.ContactReplyThread>

export const ContactReplyThread = memo<Props>(
  ({
    route: {
      params: { replyThreadId, isNew, contactTitle, senderName },
    },
    navigation,
  }) => {
    const {
      value,
      isLoading,
      partnerImageUrl,
      refreshContactReplyThreads,
      refreshPartnerImageUrl,
      execPostContactReplyThreadMessage,
      execDeleteContactReplyThreadMessage,
      execUpdateContactReplyThreadMessage,
      setNextTokens,
      organizationId,
    } = useContactReplyThreadData(replyThreadId, isNew, navigation)

    const [message, setMessage] = useState<string>()
    const [toShowTextInput, setToShowTextInput] = useState(false)

    const [showableMessageMenuId, setShowableMessageMenuId] = useState<string>()

    const textInputScrollViewRef = useRef<ScrollView>(null)
    const textInputRef = useRef<TextInput>(null)

    const [editingMessageId, setEditingMessageId] = useState<string>()

    const copyMessage = useCallback((message: string) => {
      Clipboard.setStringAsync(message)
      setShowableMessageMenuId(undefined)
    }, [])

    const editMessage = useCallback((id: string, message: string) => {
      setMessage(message)
      setShowableMessageMenuId(undefined)
      setEditingMessageId(id)
      textInputRef.current?.focus()
    }, [])

    const removeMessage = useCallback(
      (replyThreadMessageId: string) => {
        setShowableMessageMenuId(undefined)
        execDeleteContactReplyThreadMessage(replyThreadMessageId)
      },
      [execDeleteContactReplyThreadMessage]
    )

    const { fetch: postMessage, isFetching: isPostingMessage } = useFetcher(
      useCallback(async () => {
        if (message == null) {
          return
        }
        await execPostContactReplyThreadMessage({
          message,
          callback: () => {
            setMessage(undefined)
            textInputRef.current?.blur()
          },
        })
        setMessage("")
        if (organizationId != null) await Analytics.sendThreadMessage(organizationId)
      }, [execPostContactReplyThreadMessage, message, organizationId])
    )

    const cancelEditMessage = useCallback(() => {
      setMessage(undefined)
      setEditingMessageId(undefined)
      textInputRef.current?.blur()
    }, [])

    const { fetch: updateMessage, isFetching: isUpdatingMessage } = useFetcher(
      useCallback(async () => {
        if (editingMessageId == null) {
          return
        }
        if (message == null) {
          await CustomAlert.alert("エラー", "メッセージが入力されていません")
          return
        }
        await execUpdateContactReplyThreadMessage({
          message,
          replyThreadMessageId: editingMessageId,
          callback: () => {
            setMessage(undefined)
            setEditingMessageId(undefined)
            textInputRef.current?.blur()
          },
        })
      }, [message, editingMessageId, execUpdateContactReplyThreadMessage])
    )

    const isLargeScreen = useCheckPCScreen()

    const handlePressOutside = () => {
      if (Platform.OS !== "web") return
      Keyboard.dismiss()
    }

    const renderItem = useCallback(
      ({ item }: ListRenderItemInfo<ContactReplyThreadItem>) =>
        item.contactReplyThreadType === ContactReplyThreadType.ReplyFromMe ? (
          <View style={styles.replyFromMeContainer}>
            <View style={styles.replyFromMeMessageWrapper}>
              <View style={{ display: "flex", flexDirection: "row", alignItems: isLargeScreen ? "flex-end" : "center" }}>
                <View>
                  {showableMessageMenuId !== item.replyThreadMessageId ? undefined : (
                    <View style={[isLargeScreen && styles.replyMessageMenuContainer, { paddingHorizontal: 5 }]}>
                      {!isLargeScreen && (
                        <Button
                          style={[styles.replyMessageMenuButtonContainer, !isLargeScreen && { marginBottom: 5 }]}
                          onPress={() => copyMessage(item.message)}
                        >
                          <Text style={styles.replyMessageMenuButtonText}>コピー</Text>
                        </Button>
                      )}
                      <Button
                        style={[styles.replyMessageMenuButtonContainer, !isLargeScreen && { marginBottom: 5 }]}
                        onPress={() => editMessage(item.replyThreadMessageId, item.message)}
                      >
                        <Text style={styles.replyMessageMenuButtonText}>編集</Text>
                      </Button>
                      <Button
                        style={styles.replyMessageMenuButtonContainer}
                        onPress={() => removeMessage(item.replyThreadMessageId)}
                      >
                        <Text style={styles.replyMessageMenuButtonText}>送信の取り消し</Text>
                      </Button>
                    </View>
                  )}
                  <View style={[styles.seeMoreAction]}>
                    {!isLargeScreen ? undefined : (
                      <Button
                        style={styles.buttonSeeMore}
                        onPress={() =>
                          setShowableMessageMenuId(
                            showableMessageMenuId !== item.replyThreadMessageId ? item.replyThreadMessageId : undefined
                          )
                        }
                      >
                        <SeeMoreIcon />
                      </Button>
                    )}
                  </View>
                </View>
                <View>
                  <Button
                    style={styles.replyFromMeMessageContainer}
                    onPress={() => setShowableMessageMenuId(undefined)}
                    onLongPress={() => setShowableMessageMenuId(item.replyThreadMessageId)}
                  >
                    <Text style={styles.replyFromMeMessageText}>{item.message}</Text>
                  </Button>
                  <View style={[styles.replyFromMeDateContainer]}>
                    <Text style={styles.replyFromMeDate}>{dayjs(item.date).format("HH:mm")}</Text>
                    {item.isEdited ? (
                      <>
                        <View style={styles.replyFromMeEditedIcon}>
                          <DraftIcon color={Colors.cement} size={8} />
                        </View>
                        <Text style={styles.replyFromMeDate}>編集済み</Text>
                      </>
                    ) : null}
                    {item.isRead ? (
                      <>
                        <Text style={styles.replyFromMeDate}> 既読</Text>
                      </>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.ReplyFromOthers ? (
          <View style={styles.replyFromOthersContainer}>
            <View style={styles.replyFromOthersInner}>
              {!value?.partner.isDeleted ? (
                <Image
                  style={styles.replyFromOthersSenderImage}
                  source={partnerImageUrl ? { uri: partnerImageUrl } : defaultThumbnail}
                  onError={refreshPartnerImageUrl}
                />
              ) : (
                <NoPersonIcon size={30} />
              )}
              <View style={styles.replyFromOthersMessageTriangle} />
              <View style={styles.replyFromOthersMessageWrapper}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <ReplyFromOthersMessage
                    replyThreadMessageId={item.replyThreadMessageId}
                    message={item.message}
                    isRead={item.isRead}
                    setShowableMessageMenuId={setShowableMessageMenuId}
                  />
                  <View>
                    {showableMessageMenuId !== item.replyThreadMessageId ? undefined : (
                      <View style={[isLargeScreen && styles.replyMessageMenuContainer, { paddingHorizontal: 5 }]}>
                        {!isLargeScreen && (
                          <Button style={styles.replyMessageMenuButtonContainer} onPress={() => copyMessage(item.message)}>
                            <Text style={styles.replyMessageMenuButtonText}>コピー</Text>
                          </Button>
                        )}
                      </View>
                    )}
                  </View>
                </View>
                <View style={styles.replyFromOthersDateContainer}>
                  <Text style={styles.replyFromOthersDate}>{dayjs(item.date).format("HH:mm")}</Text>
                  {item.isEdited ? (
                    <>
                      <View style={styles.replyFromOthersEditedIcon}>
                        <DraftIcon color={Colors.cement} size={8} />
                      </View>
                      <Text style={styles.replyFromOthersDate}>編集済み</Text>
                    </>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.RemovedReply ? (
          <View style={styles.replyRemovedReplyContainer}>
            <MarkAsReadMessageRemove
              replyThreadMessageId={item.replyThreadMessageId}
              isRead={true}
              itemType={item.contactReplyThreadType}
            />
            <Text style={styles.replyRemovedReplyText}>送信者が送信を取り消しました。</Text>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.DateLine ? (
          <View style={styles.replyDateLineContainer}>
            <View style={styles.replyDateLineSeparator} />
            <Text style={styles.replyDateLineLabel}>{item.label}</Text>
            <View style={styles.replyDateLineSeparator} />
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.MemberDeleted ? (
          <View style={styles.replyRemovedReplyContainer}>
            <Text style={styles.replyRemovedReplyText}>メンバーが脱退したため、返信できません。</Text>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.NoMessage ? (
          <View style={styles.noMessageContainer}>
            <Text style={styles.noMessageText}>これは送信者に対する返信で、他の受信者にはこのメッセージは表示されません。</Text>
          </View>
        ) : null,
      [
        showableMessageMenuId,
        copyMessage,
        editMessage,
        removeMessage,
        partnerImageUrl,
        refreshPartnerImageUrl,
        value?.partner.isDeleted,
        isLargeScreen,
      ]
    )

    useEffect(() => {
      if (value?.partner.name) {
        navigation.setOptions({
          headerTitle: value.partner.name + (value.partner.isDeleted ? "（脱退）" : ""),
        })
      } else {
        navigation.setOptions({
          headerTitle: senderName,
        })
      }
    }, [navigation, senderName, value?.partner])

    useEffect(() => {
      return () => refreshContactReplyThreads()
    }, [refreshContactReplyThreads])

    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (value?.nextToken != null ? [...prev, value.nextToken] : prev))
    }, [value, setNextTokens])

    const { bottom } = useSafeAreaInsets()
    const { height } = useWindowDimensions()

    if (isLoading && value == null) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    return (
      <CustomKeyboardAvoidingView>
        <Pressable
          style={[
            styles.container,
            Platform.OS !== "web" ? { maxHeight: height - (headerHeight + 16) } : { height: height - (headerHeight + 16) },
          ]}
          disabled={showableMessageMenuId == null && Platform.OS !== "web"}
          onPress={() => setShowableMessageMenuId(undefined)}
        >
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>{value ? value.contactTitle : contactTitle}</Text>
          </View>
          <FlatList
            style={[styles.replyItemsContainer, Platform.OS === "web" && { height: 500 }]}
            contentContainerStyle={styles.replyItemContainer}
            data={value?.reversedReplyItems}
            renderItem={renderItem}
            inverted={value?.hasMessage}
            onEndReached={!isLoading ? getMoreContacts : undefined}
            keyExtractor={(item) => item.replyThreadMessageId}
          />
          <View style={styles.footerContainer}>
            <View
              style={[
                styles.textInputContainer,
                {
                  paddingBottom: Platform.OS === "ios" && !toShowTextInput ? bottom : 6,
                },
              ]}
            >
              <ScrollView style={{ maxHeight: toShowTextInput ? 350 : 40 }} ref={textInputScrollViewRef}>
                <MultilineTextInput
                  style={styles.textInput}
                  ref={textInputRef}
                  minHeight={40}
                  value={message ?? ""}
                  onChangeText={setMessage}
                  maxLength={1000}
                  placeholder="返信を入力する"
                  placeholderTextColor={Colors.cement}
                  onFocus={() => setToShowTextInput(true)}
                  onBlur={() => {
                    setToShowTextInput(false)
                    handlePressOutside()
                  }}
                  onLayout={() => textInputScrollViewRef.current?.scrollToEnd()}
                  editable={!value?.partner.isDeleted}
                />
              </ScrollView>
              <View style={styles.textInputButtonContainer}>
                {editingMessageId == null ? (
                  <Button onPress={postMessage} disabled={isEmptyString(message) || isPostingMessage}>
                    <SendMessageIcon />
                  </Button>
                ) : (
                  <>
                    <Button style={styles.editCancelButton} onPress={cancelEditMessage}>
                      <CancelWithCircleIcon />
                    </Button>
                    <Button onPress={updateMessage} disabled={isUpdatingMessage || !message?.length}>
                      <CheckWithCircleIcon />
                    </Button>
                  </>
                )}
              </View>
            </View>
            {!toShowTextInput ? (
              <View style={styles.dummyTextInputContainer} pointerEvents="none">
                {message == null || message === "" ? (
                  <Text style={styles.dummyTextPlaceholder}>返信を入力する</Text>
                ) : (
                  <Text style={styles.dummyTextInput} numberOfLines={1}>
                    {message}
                  </Text>
                )}
              </View>
            ) : null}
          </View>
        </Pressable>
      </CustomKeyboardAvoidingView>
    )
  }
)

type ReplyFromOthersMessageProps = {
  replyThreadMessageId: string
  message: string
  isRead: boolean
  setShowableMessageMenuId: (id: string | undefined) => void
}
type ReplyFromOthersMessageRemoveProps = {
  replyThreadMessageId: string
  itemType: string
  isRead: boolean
}
const ReplyFromOthersMessage = memo<ReplyFromOthersMessageProps>(
  ({ replyThreadMessageId, message, isRead, setShowableMessageMenuId }) => {
    useMarkThreadMessageAsRead({ replyThreadMessageId, isRead })

    return (
      <Button
        style={styles.replyFromOthersMessageContainer}
        onPress={() => setShowableMessageMenuId(undefined)}
        onLongPress={() => setShowableMessageMenuId(replyThreadMessageId)}
      >
        <Text style={styles.replyFromOthersMessageText}>{message}</Text>
      </Button>
    )
  }
)
const MarkAsReadMessageRemove = memo<ReplyFromOthersMessageRemoveProps>(({ replyThreadMessageId, isRead, itemType }) => {
  useMarkThreadMessageAsRead({ replyThreadMessageId, isRead, itemType })
  return <></>
})

export const contactReplyThreadOptions: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  headerTitle: () => <LoadingIndicator />,
  title: "メッセージ",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden",
  },

  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    backgroundColor: "rgb(226, 117, 0)",
    paddingVertical: 11,
    paddingHorizontal: 22,
  },
  headerText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 22,
    color: Colors.white3,
  },

  replyItemsContainer: {
    backgroundColor: Colors.duckEggBlue,
  },
  replyItemContainer: {
    paddingTop: 16,
  },

  replyMessageMenuContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  replyMessageMenuButtonContainer: {
    marginHorizontal: 3,
    paddingVertical: 5,
    paddingHorizontal: 7,
    borderWidth: 1,
    borderColor: Colors.silver,
    borderRadius: 6,
    backgroundColor: Colors.white7,
    textAlign: "center",
  },
  replyMessageMenuButtonText: {
    fontSize: 13,
    color: "rgb(51,51,51)",
    textAlign: "center",
  },

  replyFromMeContainer: {
    marginTop: 16,
    alignItems: "flex-end",
    paddingRight: 20,
    position: "relative",
  },
  replyFromMeMessageWrapper: {
    alignItems: "flex-start",
  },
  replyFromMeMessageContainer: {
    borderRadius: 16,
    backgroundColor: Colors.pale2,
    paddingVertical: 15,
    paddingHorizontal: 20,
    maxWidth: 245,
  },
  replyFromMeMessageText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black,
  },
  replyFromMeDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2,
  },
  replyFromMeDate: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.3)",
  },
  replyFromMeEditedIcon: {
    marginLeft: 8,
    marginRight: 4,
  },

  replyFromOthersContainer: {
    marginTop: 16,
    alignItems: "flex-start",
    paddingLeft: 20,
  },
  replyFromOthersInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  replyFromOthersSenderImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  replyFromOthersMessageTriangle: {
    marginTop: 23,
    marginLeft: 1,
    borderRightWidth: 10,
    borderRightColor: Colors.white3,
    borderTopWidth: 5,
    borderTopColor: "transparent",
    borderBottomWidth: 5,
    borderBottomColor: "transparent",
  },
  replyFromOthersMessageWrapper: {
    alignItems: "flex-start",
  },
  replyFromOthersMessageContainer: {
    borderRadius: 16,
    backgroundColor: Colors.white3,
    paddingVertical: 15,
    paddingHorizontal: 20,
    maxWidth: 245,
  },
  replyFromOthersMessageText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black,
  },
  replyFromOthersDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2,
  },
  replyFromOthersDate: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.3)",
  },
  replyFromOthersEditedIcon: {
    marginLeft: 8,
    marginRight: 4,
  },
  replyRemovedReplyContainer: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    paddingVertical: 3,
    paddingHorizontal: 24,
    alignSelf: "center",
    borderRadius: 11,
  },
  replyRemovedReplyText: {
    fontSize: 11,
    color: "rgba(0, 0, 0, 0.29)",
  },
  noMessageContainer: {
    marginTop: 12,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 3,
    paddingHorizontal: 24,
    alignSelf: "center",
  },
  noMessageText: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.4)",
  },
  replyDateLineContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  replyDateLineSeparator: {
    backgroundColor: "rgba(0,0,0,0.08)",
    height: 1,
    flex: 1,
  },
  replyDateLineLabel: {
    fontSize: 13,
    color: "rgba(0,0,0,0.3)",
    paddingHorizontal: 18,
  },

  footerContainer: {
    position: "relative",
  },
  textInputContainer: {
    backgroundColor: Colors.white3,
    borderTopWidth: 1,
    borderTopColor: Colors.orange,
    maxHeight: 350,
  },
  textInput: {
    borderWidth: 0,
    outlineStyle: "none",
    paddingHorizontal: 16,
    // TextInputに対してpaddingVerticalが効かないバグがある
    paddingTop: 12,
    paddingBottom: 12,
    height: "100%",
    fontSize: 16,
    lineHeight: 22,
  },
  textInputButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "rgba(0,0,0,0.2)",
    paddingVertical: 6,
    paddingHorizontal: 20,
  },
  dummyTextInputContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: Colors.white3,
    borderTopWidth: 1,
    borderTopColor: Colors.orange,
    paddingRight: 20,
    paddingLeft: 20,
    width: "100%",
    height: 41,
  },
  dummyTextInput: {
    fontSize: 16,
    height: 22,
    lineHeight: 22,
    color: Colors.greyshBrown,
  },
  dummyTextPlaceholder: {
    fontSize: 16,
    height: 22,
    lineHeight: 22,
    color: Colors.cement,
  },
  editCancelButton: {
    marginRight: 12,
  },
  seeMoreAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonSeeMore: {
    marginTop: 5,
    paddingBottom: 14,
    marginRight: 5,
  },
})
