import { memo, useCallback, useEffect, useMemo } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { AccountEditor } from "src/components/parts/AccountEditor"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { useAccountFormData } from "src/components/projects/RegisterAccount/useAccountFormData"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { imageSize } from "src/recoil/hooks/resource/useResource"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSetRecoilState } from "recoil"
import { organizationMemberImageRefresherState } from "src/recoil/atoms/organization/organizationMemberImageRefresherState"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"

type Props = RootStackScreenProps<"EditAccount">

export const EditAccount = memo<Props>(({ navigation }) => {
  const setRefreshImage = useSetRecoilState(organizationMemberImageRefresherState)
  const params = useMemo<{ mode: "update"; onSuccess: () => Promise<void> }>(
    () => ({
      mode: "update",
      onSuccess: async () => {
        setRefreshImage(true)
        navigation.goBack()
        if (Platform.OS === "web") {
          setTimeout(() => {
            window.location.reload()
          }, 200)
        }
      },
    }),
    [navigation, setRefreshImage]
  )
  const {
    imageUrl,
    deleteImageFlg,
    prepareDeleteImage,
    localImageUri,
    refreshImageUrl,
    pickLocalImage,
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    birthDate,
    gender,
    setFirstName,
    setLastName,
    setKanaFirstName,
    setKanaLastName,
    setGender,
    updateBirthDate,
    email,
    isRegistering,
    isDisabled,
    update,
    jobId,
    jobOptions,
    setJobId,
    schoolOptions,
    schoolDepartmentOptions,
    schoolId,
    setSchoolId,
    schoolDepartmentId,
    setSchoolDepartmentId,
    graduationYear,
    graduationYearOptions,
    setGraduationYear,
    prefectureOptions,
    prefectureId,
    setPrefectureId,
    cityId,
    setCityId,
    questionAnswers,
    setQuestionAnswers,
    accountQuestions,
    cityOptions,
    refreshFormData,
    kanaLastNameErrorMessage,
    kanaFirstNameErrorMessage,
    birthdayErrorMessage,
  } = useAccountFormData(params)
  const isLargeScreen = useCheckPCScreen()

  const gotoSelectAccountSchool = useCallback(() => {
    if (jobId == null) {
      return
    }
    navigation.navigate(Screens.SelectAccountSchool, { jobId })
  }, [navigation, jobId])
  const showDeleteImageButton = useMemo(() => {
    return !deleteImageFlg && (localImageUri != null || (imageUrl != null && !imageUrl.toLocaleLowerCase().includes("default")))
  }, [deleteImageFlg, localImageUri, imageUrl])
  const insets = useSafeAreaInsets()
  const displayedImage = useMemo(
    () => (deleteImageFlg ? defaultThumbnail : localImageUri || imageUrl || defaultThumbnail),
    [deleteImageFlg, localImageUri, imageUrl]
  )
  useEffect(() => {
    return () => {
      refreshFormData()
    }
  }, [navigation, refreshFormData])

  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      <ScrollView>
        <View style={[styles.warningContainer, isLargeScreen ? styles.oneColumn : {}]}>
          <Text style={styles.warning}>
            ご本人の情報を登録してください。団体内で表示する個人情報は「団体内個人設定」で登録できます。
          </Text>
        </View>
        <View style={styles.thumbnailContainer}>
          <CircleThumbnail
            source={
              displayedImage === defaultThumbnail
                ? require("src/assets/images/defaultThumbnail.png")
                : displayedImage
                ? { uri: displayedImage, cache: "force-cache" }
                : { uri: imageUrl }
            }
            onError={refreshImageUrl}
          />
          <View style={styles.buttonContainer}>
            <SmallButton onPress={() => pickLocalImage({ maxSizeInMB: imageSize.account.maxSizeInMB, allowsEditing: true })}>
              <EditIcon />
              <Text style={styles.editThumbnailText}>写真を登録</Text>
            </SmallButton>
          </View>
          {showDeleteImageButton ? (
            <View style={{ paddingTop: 5 }}>
              <SmallButton onPress={prepareDeleteImage} disabled={isDisabled}>
                <RemoveIcon />
                <Text style={styles.deleteImageButtonText}>写真を削除</Text>
              </SmallButton>
            </View>
          ) : null}
        </View>
        {gender == null || email == null ? (
          <LoadingIndicator />
        ) : (
          <>
            <AccountEditor
              firstName={firstName}
              lastName={lastName}
              kanaFirstName={kanaFirstName}
              kanaLastName={kanaLastName}
              birthDate={birthDate}
              gender={gender}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setKanaFirstName={setKanaFirstName}
              setKanaLastName={setKanaLastName}
              setGender={setGender}
              updateBirthDate={updateBirthDate}
              email={email}
              jobId={jobId}
              jobOptions={jobOptions}
              setJobId={setJobId}
              schoolId={schoolId}
              setSchoolId={setSchoolId}
              schoolOptions={schoolOptions}
              schoolDepartmentId={schoolDepartmentId}
              setSchoolDepartmentId={setSchoolDepartmentId}
              schoolDepartmentOptions={schoolDepartmentOptions}
              graduationYear={graduationYear}
              setGraduationYear={setGraduationYear}
              graduationYearOptions={graduationYearOptions}
              prefectureOptions={prefectureOptions}
              prefectureId={prefectureId}
              setPrefecture={setPrefectureId}
              cityId={cityId}
              setCity={setCityId}
              cityOptions={cityOptions}
              questionAnswers={questionAnswers}
              setQuestionAnswers={setQuestionAnswers}
              accountQuestions={accountQuestions}
              gotoSelectAccountSchool={gotoSelectAccountSchool}
              kanaLastNameErrorMessage={kanaLastNameErrorMessage}
              kanaFirstNameErrorMessage={kanaFirstNameErrorMessage}
              birthdayErrorMessage={birthdayErrorMessage}
            />
            <View
              style={[
                styles.actions,
                { paddingTop: 10, paddingBottom: insets.bottom + 10 },
                isLargeScreen ? { display: "flex", alignItems: "center" } : {},
              ]}
            >
              <TextButton
                buttonType={ButtonType.Primary}
                title="保存する"
                onPress={update}
                disabled={isDisabled}
                isLoading={isRegistering}
                style={isLargeScreen ? styles.buttonsPC : {}}
              />
            </View>
          </>
        )}
      </ScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const editAccountOptions: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  title: "アカウント情報編集",
}

const styles = StyleSheet.create({
  oneColumn: {
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 160,
    width: 160,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  container: {
    backgroundColor: Colors.white,
  },
  warningContainer: {
    padding: 16,
    marginTop: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  warning: {
    color: Colors.red,
    fontWeight: "400",
    fontSize: 16,
  },
  thumbnailContainer: {
    alignItems: "center",
    paddingTop: 24,
  },
  buttonContainer: {
    paddingTop: 24,
  },
  editThumbnailText: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  deleteImageButtonText: {
    color: "#3C3C3C",
    marginLeft: 8,
    fontSize: 13,
  },
  actions: {
    paddingHorizontal: 24,
    backgroundColor: Colors.white3,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
})
