import { deleteAsync } from "./secureStorage/deleteAsync"
import { getAsync } from "./secureStorage/getAsync"
import { setAsync } from "./secureStorage/setAsync"

const key = "selectedTeam"

export const getSelectedTeam = async () => {
  const value = await getAsync(key)
  return value ?? undefined
}

export const setSelectedTeam = (selectedTeam?: string) => {
  return selectedTeam == null ? deleteAsync(key) : setAsync(key, selectedTeam)
}
