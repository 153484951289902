import { useEffect, useMemo } from "react"
import { RootNavigator } from "src/navigation/RootNavigator"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { useAsyncSelector } from "./useAsyncSelector"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { View } from "react-native"
import { useSetRecoilState } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { RegisterAccount } from "src/components/projects/RegisterAccount"
import { commonOptions } from "src/constants/options/commonHeaderOptions"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"

export const useRootScreen = () => {
  const { value, isLoading, error } = useAsyncSelector(accountSelector)
  const setTokens = useSetRecoilState(tokensState)
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)

  const RootScreen = useMemo(() => {
    if (isLoading && value == null) {
      return () => (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <LoadingIndicator />
        </View>
      )
    }
    return value?.signupStatus === "completed" ? RootNavigator : RegisterAccount
  }, [isLoading, value])

  useEffect(() => {
    if (!isLoading && value == null && error?.errors && error?.errors[0]?.message !== "Network Error") {
      setTokens(undefined)
      setSelectedOrganization(undefined)
    }
  }, [isLoading, value, error, setTokens, setSelectedOrganization])
  const rootScreenOptions = useMemo(() => {
    if (!value || value.signupStatus === "completed") {
      return {
        headerShown: false,
      }
    } else {
      return {
        ...commonOptions,
        title: "アカウント情報登録",
        headerShown: true,
        headerLeft: () => null,
      }
    }
  }, [value])

  return {
    RootScreen,
    rootScreenOptions,
  }
}
