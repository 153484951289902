import { useCallback, useMemo, useState } from "react"
import { useSetRecoilState } from "recoil"
import { deleteAccount } from "src/apis/account/deleteAccount"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { Analytics } from "src/tags/analytics/Analytics"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
const paidVersionMessage =
  "団体有料版の申込者に設定されている団体があります。退会する場合は「申込者権限を譲渡する」ボタンより、別の代表者に権限を譲渡後、再度退会手続きを行ってください。"
const paidVersionErrorCode = "エラーコード 013"
export const useDeleteAccountData = () => {
  const [reason, setReason] = useState("")
  const { value: tokens } = useAsyncSelector(tokensState)
  const setTokens = useSetRecoilState(tokensState)
  const setSession = useSetRecoilState(sessionState)
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { fetch: execDeleteAccount, isFetching: isDeletingAccount } = useFetcher(
    useCallback(async () => {
      const isConfirmed = await CustomAlert.confirm(
        "確認",
        "本当に退会しますか？\n参加中のすべての団体から脱退となります。\n元に戻すことはできません。"
      )
      if (!isConfirmed) {
        return
      }

      if (accessToken == null) {
        return
      }
      const result = await deleteAccount({
        accessToken,
        reason: reason !== "" ? reason : undefined,
      })
      if (!result.isOk) {
        if (result.error.message?.includes(paidVersionErrorCode)) {
          await CustomAlert.alert("警告", paidVersionMessage)
          return
        } else {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
      }
      await Analytics.deleteAccount()
      await CustomAlert.alert("完了", "退会しました。")
      setTokens(undefined)
      setSelectedOrganization(undefined)
      setSession(undefined)
    }, [accessToken, reason, setTokens, setSelectedOrganization, setSession])
  )

  return {
    reason,
    setReason,
    execDeleteAccount,
    isDeletingAccount,
  }
}
