/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native"
import * as Linking from "expo-linking"
import * as Notifications from "expo-notifications"
import { NotificationResponse } from "expo-notifications"

import { RootStackParamList } from "src/types.d"
import Constants from "expo-constants"
import { setUrlQuery } from "src/storage/query"
import { parseDynamicLink } from "src/utils/links/parseDynamicLink"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { RootDrawer } from "src/constants/RootDrawer"
import { Platform } from "react-native"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { setAsync } from "src/storage/secureStorage/setAsync"

const prefix = Linking.createURL("/")
const prefix_link = `https://${Constants.expoConfig?.extra?.deepLinkDomain}/dlink`

interface iosPushPayload {
  payload: {
    aps: any
    metadeta: any
    urlScheme: string
  }
  class: string
  type: string
}

interface androidPushPayload {
  payload: {
    notification: any
    collapse_key: any
    priority: string
    data: {
      urlScheme: string
      metadata: string
    }
  }
  remoteMessage: {
    notification: any
    collapse_key: any
    priority: string
    data: {
      urlScheme: string
      metadata: string
    }
  }
  class: string
  type: string
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [prefix, prefix_link],
  config: {
    screens: {
      Root: {
        screens: {
          HomeTab: "home",
          ContactNetworkDrawer: {
            screens: {
              InboxScreen: "cn/inbox",
              OutboxScreen: "cn/outbox",
              DraftScreen: "cn/draft",
              TemplateScreen: "cn/template",
              TrashBoxScreen: "cn/trashbox",
              MigrationScreen: "cn/migration",
            },
          },
          OrganizationManagerTab: {
            screens: {
              OrganizationManager: "org",
              OrganizationDetail: "org/detail",
              MigrateOrganizations: "MigrateOrganizations",
              SendMemberInMigrate: "SendMemberInMigrate",
              OrganizationMembers: "OrganizationMembers",
              OrganizationGroups: "OrganizationGroups",
              OrderDetail: "OrderDetail",
              MatchingDrawer: {
                screens: {
                  OtherMatchingOffer: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.OtherMatchingOffer,
                  MyMatchingOffer: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.MyMatchingOffer,
                  MyMatchingProfile: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.MyMatchingProfile,
                  FavoriteMatchingOffer: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.FavoriteMatchingOffer,
                  MyMatchingDraftOffer: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.MyMatchingDraftOffer,
                  Applications: RootDrawer.MatchingDrawer + "/" + MatchingDrawers.Applications,
                },
              },
            },
          },
          SettingsTab: {
            screens: {
              SettingsTab: "settings",
              Information: "Information",
              AccountInfo: "AccountInfo",
              EditAccount: "EditAccount",
              Licenses: "Licenses",
              Inquiry: "Inquiry",
              InformationDetail: "InformationDetail",
              NotificationSettings: "NotificationSettings",
            },
          },
        },
      },
      CreateOrganizationStack: "org/create",
      JoinOrganization: "org/join",
      AuthorizationTop: "auth",
      SignupEmail: "auth/signup/email",
      SignupCode: "auth/signup/code",
      LoginEmail: "auth/login/email",
      LoginPhoneNumber: "auth/login/sms",
      LoginCode: "auth/login/code",
      AccountInfo: "settings/account",
      EditEmail: "settings/account/email",
      EditOrRegisterPhoneNumber: "settings/account/sms",
      ContactDetail: "contacts",
      ContactReplyThread: "replies",
      PendingInvitations: "invitations",
      InvitationDialog: "invitations/dialog",
      InvitationMember: "org/:id/invite",
      DlinkRedirect: "dlink/*",
      NotFound: "*",
      MigrationLogin: "migration/migration_login",
      MigrationTeamSelect: "migration/migration_team_select",
      MigrationTeamConfirmation: "migration/migration_team_confirmation",
      MigrationTeamComplete: "migration/migration_team_complete",
      MailMigrationAsMember: "migration/migration_team_confirmation_with_member/mail",
      MigrationMailConfirmation: "migration/migration_mail_confirmation",
      MatchingThread: RootDrawer.MatchingDrawer + "/thread",
    },
  },
  // 初期設定のURL
  async getInitialURL() {
    const uri = (await Linking.getInitialURL()) ?? ""

    if (Platform.OS === "android") {
      const urlScheme = (await getAsync("urlScheme")) ?? ""
      if (urlScheme !== "") {
        setAsync("urlScheme", "")
        if (uri === "") return `${prefix}${urlScheme}`
      }
    }
    return uri
  },
  // push通知から取得
  subscribe(listener) {
    const onReceiveURL = async ({ url }: { url: string }) => {
      listener(url)
    }

    Linking.addEventListener("url", onReceiveURL)

    // ユーザーが通知をタップまたは操作したときに発生します
    // （アプリがフォアグラウンド、バックグラウンド、またはキルされたときに動作します）
    const subscription = Notifications.addNotificationResponseReceivedListener((response: NotificationResponse) => {
      const trigger_ios = response?.notification?.request?.trigger as iosPushPayload
      const trigger_android = response?.notification?.request?.trigger as androidPushPayload
      const urlScheme =
        trigger_ios?.payload?.urlScheme ||
        trigger_android?.payload?.data?.urlScheme ||
        trigger_android?.remoteMessage?.data?.urlScheme

      if (urlScheme !== "") {
        setTimeout(() => {
          listener(`${prefix}${urlScheme}`)
        }, 1000)
      }
    })

    return () => {
      subscription.remove()
    }
  },
}

export default linking
