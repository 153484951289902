export const fileTypeSet = {
  images: [".png", ".gif", ".jpeg", ".jpg"],
  docsAndImages: [".png", ".gif", ".jpeg", ".jpg", ".pdf", ".txt", ".xlsx", ".xls", ".ppt", ".pptx", ".doc", ".docx", ".csv"],
}

export const extensionToMimeType: Record<string, string> = {
  png: "image/png",
  gif: "image/gif",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  pdf: "application/pdf",
  txt: "text/plain",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  csv: "text/csv",
}
