import { ChangeEventHandler, memo, useCallback, useMemo, useRef } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Props } from "./Props"
import { PickerViewType } from "src/components/parts/CustomDateTimePicker/PickerViewType"
import { CustomAlert } from "src/utils/CustomAlert"
import dayjs from "dayjs"
import { getDefaultDate } from "src/utils/getDefaultDate"
import { Checkbox } from "src/components/parts/Checkbox"
import { Colors } from "src/constants/Colors"

export const CustomDateTimePicker = memo<Props>(
  ({
    value,
    onChange,
    title,
    viewType = PickerViewType.DateTime,
    min,
    max,
    isDisabled,
    errorMessage,
    minuteInterval = 1,
    isCheckMinDate,
  }) => {
    const currentDay = dayjs().format("YYYY-MM-DD")
    const cacheRef = useRef<Date>()
    const cache = useMemo(() => {
      if (value != null) {
        cacheRef.current = value
      }
      return cacheRef.current ?? getDefaultDate()
    }, [value])
    const isActive = useMemo(() => value != null, [value])
    const toggleIsScheduleEnabled = useCallback(() => {
      if (isActive) {
        onChange(undefined)
      } else {
        onChange(cache)
      }
    }, [isActive, onChange, cache])
    const dateText = useMemo(() => dayjs(cache).format("YYYY-MM-DD"), [cache])
    const hour = useMemo(() => cache.getHours(), [cache])
    const minutes = useMemo(() => cache.getMinutes(), [cache])
    const isDateVisible = useMemo(() => viewType === PickerViewType.DateTime || viewType === PickerViewType.Date, [viewType])
    const isTimeVisible = useMemo(() => viewType === PickerViewType.DateTime || viewType === PickerViewType.Time, [viewType])
    const checkDate = useCallback(
      (date: Date) => {
        if (date.getTime() !== date.getTime()) {
          CustomAlert.alert("エラー", "不正な値です。")
          return false
        }
        if (isCheckMinDate) {
          min?.setHours(0, 0, 0, 0)
        }
        if (min != null && min > date) {
          CustomAlert.alert("エラー", "下限を下回った値です。")
          return false
        }
        if (max != null && max < date) {
          CustomAlert.alert("エラー", "上限を上回った値です。")
          return false
        }
        return true
      },
      [min, max, isCheckMinDate]
    )
    const onDateChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        if (!e.target.value) {
          onChange(undefined)
          return
        }
        const newDateSubString = e.target.value.split("-")
        const newDate = new Date(
          Number(newDateSubString[0]),
          Number(newDateSubString[1]) - 1,
          Number(newDateSubString[2]),
          cache.getHours(),
          cache.getMinutes()
        )
        if (!checkDate(newDate)) {
          return
        }
        onChange(newDate)
      },
      [onChange, cache, checkDate]
    )
    const onHourChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
      (e) => {
        const newDate = new Date(
          cache.getFullYear(),
          cache.getMonth(),
          cache.getDate(),
          Number(e.target.value),
          cache.getMinutes()
        )
        if (!checkDate(newDate)) {
          return
        }
        onChange(newDate)
      },
      [onChange, cache, checkDate]
    )
    const onMinutesChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
      (e) => {
        const newDate = new Date(
          cache.getFullYear(),
          cache.getMonth(),
          cache.getDate(),
          cache.getHours(),
          Number(e.target.value)
        )
        if (!checkDate(newDate)) {
          return
        }
        onChange(newDate)
      },
      [onChange, cache, checkDate]
    )
    const minDate = useMemo(() => (min != null ? dayjs(min).format("YYYY-MM-DD") : undefined), [min])
    const maxDate = useMemo(() => (max != null ? dayjs(max).format("YYYY-MM-DD") : undefined), [max])
    const hoursOptions = useMemo(
      () =>
        Array(24)
          .fill("")
          .map((v, i) => i),
      []
    )
    const minutesOptions = useMemo(
      () =>
        Array(60 / minuteInterval)
          .fill("")
          .map((v, i) => i * minuteInterval),
      [minuteInterval]
    )

    const checkDisabledHours = (value: number) => {
      if (!minDate || dateText > currentDay) {
        return false
      }
      if (!!min && value < min.getHours()) {
        return true
      }
      return false
    }
    const checkDisabledMinutes = (value: number) => {
      if (!minDate || dateText > currentDay) return false
      if (!!min && min.getHours() === cache.getHours() && value < min.getMinutes()) return true
      if (value % minuteInterval !== 0) return true
      return false
    }

    const showError = errorMessage && errorMessage.length !== 0
    return (
      <View style={[styles.container, showError ? { width: "100%" } : {}]}>
        {title != null ? (
          <View style={styles.checkboxContainer}>
            <Checkbox isChecked={isActive} onPress={toggleIsScheduleEnabled} isDisabled={isDisabled} />
            <Text style={[styles.checkboxLabel, isDisabled && styles.disabledLabel]}>{title}</Text>
          </View>
        ) : null}
        <View style={styles.pickerContainer}>
          {isDateVisible ? (
            <input
              style={isActive ? styles.inputContainer : styles.inputContainerInactive}
              type="date"
              value={dateText}
              disabled={isDisabled}
              onChange={onDateChange}
              min={minDate}
              max={maxDate}
            />
          ) : null}
          {isTimeVisible ? (
            <View style={styles.selectsContainer}>
              <select
                style={isActive ? styles.selectContainer : styles.selectContainerInactive}
                value={hour}
                onChange={onHourChange}
                disabled={isDisabled}
              >
                {hoursOptions.map((v) => (
                  <option value={v} key={v} disabled={checkDisabledHours(v)}>
                    {v}
                  </option>
                ))}
              </select>
              <Text style={styles.selectText}> : </Text>
              <select
                style={isActive ? styles.selectContainer : styles.selectContainerInactive}
                value={minutes}
                onChange={onMinutesChange}
                disabled={isDisabled}
              >
                {minutesOptions.map((v) => (
                  <option value={v} key={v} disabled={checkDisabledMinutes(v)}>
                    {`0${v}`.slice(-2)}
                  </option>
                ))}
              </select>
            </View>
          ) : null}
        </View>
        {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
      </View>
    )
  }
)

const commonStyles = StyleSheet.create({
  commonInputContainer: {
    position: "relative",
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: "center",
    height: 40,
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 16,
    fontSize: 14,
  },
  commonSelectContainer: {
    position: "relative",
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: "center",
    height: 40,
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 14,
  },
})

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
  },
  pickerContainer: {
    flexDirection: "row",
  },
  checkboxContainer: {
    paddingBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  checkboxLabel: {
    paddingLeft: 8,
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  disabledLabel: {
    color: Colors.warmGrey,
  },
  inputContainer: {
    ...commonStyles.commonInputContainer,
    borderColor: "#B4B4B4",
  },
  inputContainerInactive: {
    ...commonStyles.commonInputContainer,
    borderColor: "#a4a4a4",
    color: "#a4a4a4",
    backgroundColor: Colors.white3,
  },
  selectContainer: {
    ...commonStyles.commonSelectContainer,
    borderColor: "#B4B4B4",
  },
  selectContainerInactive: {
    ...commonStyles.commonSelectContainer,
    borderColor: "#a4a4a4",
    color: "#a4a4a4",
    backgroundColor: Colors.white3,
  },
  selectsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  error: {
    fontSize: 14,
    color: Colors.red,
    fontWeight: "bold",
    padding: 0,
  },
})
