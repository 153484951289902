import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Image, Modal, Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomDateTimePicker } from "src/components/parts/CustomDateTimePicker"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { ContactType, ContactTypeLabel, contactTypeOptions } from "src/constants/ContactType"
import { Questions } from "src/constants/Questions"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { imageSize, useResource } from "src/recoil/hooks/resource/useResource"
import { ValueOf } from "src/types.d"
import { EventDateCandidate, formatCandidate } from "src/types/contact/EventDateCandidate"
import type { Receivers } from "src/types/contact/Receivers"
import { ResourceUnit } from "src/types/resource/ResourceUnit"
import { CustomAlert } from "src/utils/CustomAlert"
import { Result } from "src/utils/Result"
import { wait } from "src/utils/wait"
import { QuestionsEditor } from "./QuestionsEditor"
import { templateContactsSelector } from "src/recoil/selectors/contact/templateContactsSelector"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { AttachmentViewer } from "src/components/parts/contactNetworkTab/AttachmentViewer"
import { DocumentIcon } from "src/components/parts/icons/DocumentIcon"
import { LocalDocument, LocalImage } from "src/types/resource/LocalResource"
import { requestBodySelector } from "src/recoil/selectors/contact/create/requestBodySelector"
import { CreateNewContactErrors } from "src/constants/contact/CreateNewContactErrors"
import { draftIdState } from "src/recoil/atoms/contact/create/draftIdState"
import { useRecoilState } from "recoil"
import { defaultTeam } from "src/constants/defaultTeam"
import { debounce } from "lodash"
import { useCheckPCScreen, useCheckPCSmallScreen } from "src/hooks/useCheckPCScreen"
import { isExpired } from "src/recoil/hooks/resource/fileManager/common"
type Props = {
  mode: "New" | "Edit" | "EditReserved"
  contactId?: string
  reservedId?: string
  organizationId: string
  sendDate: string | Date
  organizationName: string
  organizationThumbnailURL?: string
  contactType: ContactType
  templateId?: string
  receivers?: Receivers
  title: string
  body: string
  isAddLeadersSentHistory?: boolean
  reservationDate?: Date
  isPublishAnswers?: boolean
  isDeadlineSet?: boolean
  toggleIsDeadlineSet?: (next: boolean) => void
  deadlineDate?: Date
  isReminderSet?: boolean
  toggleIsReminderSet?: () => void
  reminderDate?: Date
  isSelectionTypeSingle?: boolean
  answerOptions?: Readonly<string[]>
  eventStartDate?: Date
  eventEndDate?: Date
  eventDateCandidates?: EventDateCandidate[]
  onContactTypeChange: (next: ContactType) => void
  onTemplateIdChange?: (templateId?: string) => void
  onReceiverIdsChange?: (receiverIds: number[]) => void
  onTitleChange: (title: string) => void
  onBodyChange: (body: string) => void
  onIsAddLeadersSentHistoryChange?: (next: boolean) => void
  onReservationDateChange?: (next?: Date) => void
  onIsPublishAnswersChange?: (next: boolean) => void
  onDeadlineDateChange?: (next?: Date) => void
  onReminderDateChange?: (next?: Date) => void
  onIsSelectionTypeSingleChange?: (next?: boolean) => void
  onAnswerOptionsChange?: (next: Readonly<string[]>) => void
  onEventStartDateChange?: (next?: Date) => void
  onEventEndDateChange?: (next?: Date) => void
  setEventDateCandidates?: (next?: EventDateCandidate[]) => void
  resetReceiverType?: () => void
  primaryLabel: string
  primaryAction: () => Promise<void>
  isPrimaryDisabled?: boolean
  isPrimaryLoading?: boolean
  secondaryLabel: string
  secondaryAction: () => Promise<void>
  isSecondaryDisabled?: boolean
  isSecondaryLoading?: boolean
  openSelectReceiversModal: () => void
  questions?: Questions
  onQuestionsChange: (next: Questions) => void
  gotoSelectEventDateCandidates: () => void
  tempMailId: string
  attachmentFileNames?: string[]
  attachmentItemProps: Readonly<AttachmentItemProps[]>
  setAttachmentItemProps: Dispatch<SetStateAction<Readonly<AttachmentItemProps[]>>>
  setAttachmentUploaders: Dispatch<SetStateAction<Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>>>
  setAttachmentThumbnailUploaders: Dispatch<SetStateAction<Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>>>
}

/** dp = Default Padding */
const dp = 16

/** ddp = Double Default Padding */
const ddp = dp * 2

const maxFileCount = imageSize.attachment.maxCount
const maxEachFileSizeInMB = imageSize.attachment.maxSizeInMB
const maxTotalFileSizeInMB = maxEachFileSizeInMB * maxFileCount
export const messageErrorMaxTotalFile = "ファイルのサイズが上限(50MB)を越えています。"

export const getMB = (size: number, isRounded = false) => {
  const exact = size / 1024 ** 2
  if (isRounded) {
    return Math.round(exact * 10) / 10
  } else {
    return exact
  }
}

export const getSizeStr = (size: number) => {
  const exact = size / 1024 ** 2
  const mb = Math.round(exact * 10) / 10
  if (mb > 0) {
    return `${mb}MB`
  } else {
    const kb = Math.round((size * 10) / 1024) / 10
    return `${kb}KB`
  }
}

export const NextActionAfterModalClosed = Object.freeze({
  OpenImagePicker: "OpenImagePicker",
  OpenDocumentPicker: "OpenDocumentPicker",
} as const)

export type NextActionAfterModalClosed = ValueOf<typeof NextActionAfterModalClosed>

const emptyUploader = (): Promise<Result<undefined, { message: string }>> => new Promise((r) => r(Result.Ok(undefined)))

const getExtension = (uri: string): string => {
  const split = uri.split(".")
  return split.length > 0 ? split.slice(-1)[0] : ""
}

export const ContactDetailEditor = memo<Props>(
  ({
    mode,
    contactId,
    reservedId,
    organizationId,
    organizationName,
    organizationThumbnailURL,
    contactType,
    onContactTypeChange,
    templateId,
    onTemplateIdChange,
    sendDate,
    receivers,
    title,
    onTitleChange,
    body,
    onBodyChange,
    isAddLeadersSentHistory,
    onIsAddLeadersSentHistoryChange,
    reservationDate,
    onReservationDateChange,
    isPublishAnswers,
    onIsPublishAnswersChange,
    deadlineDate,
    onDeadlineDateChange,
    reminderDate,
    onReminderDateChange,
    primaryLabel,
    primaryAction,
    isPrimaryDisabled,
    isPrimaryLoading,
    secondaryLabel,
    secondaryAction,
    isSecondaryDisabled,
    isSecondaryLoading,
    openSelectReceiversModal,
    questions,
    onQuestionsChange,
    gotoSelectEventDateCandidates,
    eventDateCandidates,
    tempMailId,
    attachmentFileNames,
    attachmentItemProps,
    setAttachmentItemProps,
    setAttachmentUploaders,
    setAttachmentThumbnailUploaders,
    setEventDateCandidates,
    resetReceiverType,
  }) => {
    const { value: templateCandidates } = useAsyncSelector(templateContactsSelector)
    const { value: requestBody } = useAsyncSelector(requestBodySelector)
    const [draftId, setDraftIdState] = useRecoilState(draftIdState)
    const prevTitleRef = useRef<string | undefined>(title)
    const prevBodyRef = useRef<string | undefined>(body)
    const prevContactTypeRef = useRef<string | undefined>(contactType)
    const prevReceiversRef = useRef<Receivers | undefined>(receivers)
    const templateItems = useMemo(
      () => templateCandidates?.map((item) => ({ value: item.id, label: item.title })),
      [templateCandidates]
    )

    const [isFirstLoaded, setFirstLoad] = useState(false)

    useEffect(() => {
      if (!isFirstLoaded) {
        if (mode === "New") {
          onReservationDateChange?.(undefined)
          onReminderDateChange?.(undefined)
          onDeadlineDateChange?.(undefined)
          setEventDateCandidates?.(undefined)
          if (!draftId) {
            resetReceiverType?.()
          }
          onQuestionsChange([])
        }
        if (mode === "EditReserved" && reservationDate) {
          onIsAddLeadersSentHistoryChange?.(false)
        }

        setFirstLoad(true)
      }
    }, [
      isFirstLoaded,
      reservationDate,
      onReservationDateChange,
      mode,
      onIsAddLeadersSentHistoryChange,
      onDeadlineDateChange,
      onReminderDateChange,
      setEventDateCandidates,
      resetReceiverType,
      onQuestionsChange,
      draftId,
    ])

    useEffect(() => {
      if (mode === "New") {
        const delayedSecondaryAction = debounce(secondaryAction, 500)

        const shouldCallSecondaryAction =
          contactType !== "Normal" ||
          (receivers && Object.keys(receivers).length > 0) ||
          title !== prevTitleRef.current ||
          body !== prevBodyRef.current ||
          contactType !== prevContactTypeRef.current ||
          receivers !== prevReceiversRef.current

        if (shouldCallSecondaryAction) {
          delayedSecondaryAction()
        }

        prevTitleRef.current = title
        prevBodyRef.current = body
        prevContactTypeRef.current = contactType
        prevReceiversRef.current = receivers

        return () => {
          delayedSecondaryAction.cancel()
        }
      }
    }, [contactType, receivers, title, body, secondaryAction, prevTitleRef, prevBodyRef, mode])
    const { bottom } = useSafeAreaInsets()
    const isContactTypeAttendance = useMemo(() => contactType === ContactType.Attendance, [contactType])
    const isContactTypeSurvey = useMemo(() => contactType === ContactType.Survey, [contactType])
    const isContactTypeScheduleAdjustment = useMemo(() => contactType === ContactType.ScheduleAdjustment, [contactType])
    const isLargeScreen = useCheckPCScreen()
    const isSmallScreen = useCheckPCSmallScreen()
    const toggleIsAddLeadersSentHistory = useCallback(
      () => onIsAddLeadersSentHistoryChange?.(!isAddLeadersSentHistory),
      [isAddLeadersSentHistory, onIsAddLeadersSentHistoryChange]
    )

    // 以下、添付ファイル操作作業
    const onRemove = useCallback(
      (index: number) => {
        setAttachmentItemProps((prev) => {
          let newValue = [...prev]
          newValue.splice(index, 1)
          newValue = newValue.map((el, i) => {
            el.index = i
            return el
          })
          return newValue
        })
        setAttachmentUploaders((prev) => {
          const newValue = [...prev]
          newValue.splice(index, 1)
          return newValue
        })
        setAttachmentThumbnailUploaders((prev) => {
          const newValue = [...prev]
          newValue.splice(index, 1)
          return newValue
        })
      },
      [setAttachmentItemProps, setAttachmentUploaders, setAttachmentThumbnailUploaders]
    )

    const attachmentsSize = useMemo(
      () =>
        getMB(
          attachmentItemProps.reduce((prev, { sizeInB }) => prev + sizeInB, 0),
          true
        ),
      [attachmentItemProps]
    )

    const attachmentsCount = useMemo(() => attachmentItemProps.length, [attachmentItemProps])

    const nextActionRef = useRef<NextActionAfterModalClosed>()

    const validateAttachment = useCallback(
      (attachment: AttachmentItemProps): boolean => {
        if (maxTotalFileSizeInMB <= attachmentsSize + getMB(attachment.sizeInB, true)) {
          CustomAlert.alert("警告", `ファイルサイズが${maxTotalFileSizeInMB}MBを超えています。`)
          return false
        } else {
          return true
        }
      },
      [attachmentsSize]
    )

    const onUploaderChange = useCallback(
      (resourceUnit: ResourceUnit, uploader: () => Promise<Result<undefined, { message: string }>>) => {
        if (resourceUnit.type === "attachmentFile") {
          const index = resourceUnit.index
          setAttachmentUploaders((prevState) => {
            const newUploaders = [...prevState]
            newUploaders.splice(index, 1, uploader)
            return newUploaders
          })
        } else if (resourceUnit.type === "attachmentThumbnail") {
          const index = resourceUnit.index
          setAttachmentThumbnailUploaders((prev) => {
            const newUploaders = [...prev]
            newUploaders.splice(index, 1, uploader)
            return newUploaders
          })
        }
      },
      [setAttachmentUploaders, setAttachmentThumbnailUploaders]
    )

    const onLocalImageChange = useCallback(
      (localImage: LocalImage, resetLocalResource: () => void) => {
        if (tempMailId == null) return false

        const extension = getExtension(localImage.uri)

        const newProps: AttachmentItemProps = {
          type: "image",
          teamId: organizationId,
          mailId: tempMailId,
          index: attachmentsCount,
          fileName: localImage.fileName || `デバイスの画像.${extension}`,
          sizeInB: localImage.sizeInB,
          onRemove,
          localImage: localImage,
          onUploaderChange,
        }
        if (!validateAttachment(newProps)) return false
        setAttachmentItemProps((prev) => [...prev, newProps])
        setAttachmentUploaders((prev) => [...prev, emptyUploader])
        setAttachmentThumbnailUploaders((prev) => [...prev, emptyUploader])
        resetLocalResource()
        return true
      },
      [
        organizationId,
        attachmentsCount,
        tempMailId,
        validateAttachment,
        onRemove,
        onUploaderChange,
        setAttachmentItemProps,
        setAttachmentUploaders,
        setAttachmentThumbnailUploaders,
      ]
    )

    const onLocalDocumentChange = useCallback(
      (localDocument: LocalDocument, resetLocalResource: () => void) => {
        if (tempMailId == null) return false

        const newProps: AttachmentItemProps = {
          type: "document",
          teamId: organizationId,
          mailId: tempMailId,
          index: attachmentsCount,
          fileName: localDocument.fileName,
          sizeInB: localDocument.sizeInB,
          onRemove,
          localDocument: localDocument,
          onUploaderChange,
        }
        if (!validateAttachment(newProps)) return false

        setAttachmentItemProps((prev) => [...prev, newProps])
        resetLocalResource()
        return true
      },
      [organizationId, attachmentsCount, setAttachmentItemProps, tempMailId, validateAttachment, onRemove, onUploaderChange]
    )

    // ローカルで画像を扱うためのHook
    const { pickLocalImage, pickLocalDocument } = useResource({
      type: "none",
      onLocalImageChange,
      onLocalDocumentChange,
    })

    const [isOpenAttachmentsModal, setIsOpenAttachmentsModal] = useState(false)
    const openAttachmentsModal = useCallback(async () => {
      if (attachmentsCount >= maxFileCount) {
        CustomAlert.alert("エラー", `添付ファイルの上限は${maxFileCount}までです。`)
        return
      }
      if (Platform.OS === "web") {
        const maxFileSizeUpload = Math.floor((maxTotalFileSizeInMB - attachmentsSize) * 100) / 100
        await pickLocalDocument({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
      } else {
        setIsOpenAttachmentsModal(true)
      }
    }, [attachmentsCount, pickLocalDocument, attachmentsSize])

    useEffect(() => {
      const handleNextAction = async () => {
        const maxFileSizeUpload = Math.floor((maxTotalFileSizeInMB - attachmentsSize) * 100) / 100
        await wait(30) // MEMO: Workaround for unexpectedly pickers are closing just after modal is closed. 30 is magic, no meaning.
        switch (nextActionRef.current) {
          case undefined:
            return
          case NextActionAfterModalClosed.OpenImagePicker: {
            nextActionRef.current = undefined
            await pickLocalImage({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
            return
          }
          case NextActionAfterModalClosed.OpenDocumentPicker: {
            nextActionRef.current = undefined
            await pickLocalDocument({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
            return
          }
        }
      }
      if (!isOpenAttachmentsModal && nextActionRef.current != null) {
        handleNextAction()
      }
    }, [attachmentsCount, attachmentsSize, isOpenAttachmentsModal, pickLocalImage, pickLocalDocument])
    const closeAttachmentsModal = useCallback(async (nextAction?: NextActionAfterModalClosed) => {
      nextActionRef.current = nextAction
      setIsOpenAttachmentsModal(false)
    }, [])
    const closeAttachmentsModalOnly = useCallback(() => closeAttachmentsModal(), [closeAttachmentsModal])
    const openImagePicker = useCallback(
      () => closeAttachmentsModal(NextActionAfterModalClosed.OpenImagePicker),
      [closeAttachmentsModal]
    )
    const openDocumentPicker = useCallback(
      () => closeAttachmentsModal(NextActionAfterModalClosed.OpenDocumentPicker),
      [closeAttachmentsModal]
    )
    const [isUsingTemplateId, setIsUsingTemplateId] = useState(false)
    const toggleIsUsingTemplateId = useCallback(() => setIsUsingTemplateId((prev) => !prev), [setIsUsingTemplateId])
    const toggleIsPublishAnswers = useCallback(
      () => onIsPublishAnswersChange?.(!isPublishAnswers),
      [onIsPublishAnswersChange, isPublishAnswers]
    )
    const handleDeadlineDateChange = useCallback((next?: Date) => onDeadlineDateChange?.(next), [onDeadlineDateChange])
    const handleReminderDateChange = useCallback((next?: Date) => onReminderDateChange?.(next), [onReminderDateChange])

    let invalidDeadlineDateError = ""
    let invalidReminderDateError = ""
    let invalidReservationDateError = ""
    if (requestBody && requestBody.length !== 0 && requestBody[0].isOk === false) {
      const invalidDeadlineDateItem = requestBody.find(
        (item) => item.isOk === false && item.error === CreateNewContactErrors.InvalidDeadlineDate
      )
      if (invalidDeadlineDateItem?.isOk === false) {
        invalidDeadlineDateError = invalidDeadlineDateItem.errorMessage ?? ""
      }
      const invalidReminderDateItem = requestBody.find(
        (item) => item.isOk === false && item.error === CreateNewContactErrors.InvalidReminderDate
      )
      if (invalidReminderDateItem?.isOk === false) {
        invalidReminderDateError = invalidReminderDateItem.errorMessage ?? ""
      }
      const invalidReservationDateItem = requestBody.find(
        (item) => item.isOk === false && item.error === CreateNewContactErrors.InvalidReservationDate
      )
      if (invalidReservationDateItem?.isOk === false) {
        invalidReservationDateError = invalidReservationDateItem.errorMessage ?? ""
      }
    }

    return (
      <>
        <ScrollView>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View
              style={
                isLargeScreen
                  ? {
                      width: "60%",
                      maxWidth: 1020,
                      minWidth: 600,
                      marginLeft: "20%",
                      marginRight: "20%",
                      paddingLeft: 30,
                      paddingRight: 30,
                    }
                  : { width: "100%" }
              }
            >
              <View style={styles.organizationContainer}>
                <CircleThumbnail
                  size={32}
                  source={organizationThumbnailURL ? { uri: organizationThumbnailURL } : defaultTeam}
                />
                <Text style={styles.organizationName}>{organizationName}</Text>
              </View>
              <View style={styles.container}>
                {mode === "New" || mode === "EditReserved" ? (
                  <View style={[styles.template]}>
                    <View
                      style={[
                        isLargeScreen
                          ? { justifyContent: "center", flexDirection: "row", alignItems: "center" }
                          : styles.templateRow,
                      ]}
                    >
                      <Checkbox isChecked={isUsingTemplateId} onPress={toggleIsUsingTemplateId} />
                      <Text style={styles.templateLabel}>テンプレートを利用する</Text>
                    </View>
                    {isUsingTemplateId && onTemplateIdChange != null ? (
                      <View style={styles.templatePicker}>
                        <CustomSelect value={templateId} options={templateItems ?? []} onChange={onTemplateIdChange} />
                      </View>
                    ) : null}
                  </View>
                ) : null}
                <View style={styles.contactType}>
                  <ItemLabel label="連絡種別" required />
                  <View style={styles.contactTypePicker}>
                    {mode === "New" || mode === "EditReserved" ? (
                      <CustomSelect value={contactType} options={contactTypeOptions} onChange={onContactTypeChange} required />
                    ) : (
                      <Text style={styles.readOnlyValue}>{ContactTypeLabel[contactType]}</Text>
                    )}
                  </View>
                </View>
                <View style={isLargeScreen ? styles.toPickerLarge : styles.to}>
                  <View style={isLargeScreen && { width: 150 }}>
                    <ItemLabel label="宛先" required />
                  </View>
                  <View
                    style={[
                      isLargeScreen
                        ? {
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }
                        : styles.toPicker,
                    ]}
                  >
                    <View style={!isLargeScreen && styles.receiversLabelContainer}>
                      {receivers?.isAllMembers ? <Text style={styles.receiversLabel}>団体全員</Text> : null}
                      {receivers?.isAllLeaders ? <Text style={styles.receiversLabel}>代表者のみ</Text> : null}
                      {receivers?.groupIds != null && receivers.groupIds.length > 0 ? (
                        <Text style={styles.receiversLabel}>選択中のグループ（{receivers.groupIds.length}）</Text>
                      ) : null}
                      {receivers?.memberIds != null && receivers.memberIds.length > 0 ? (
                        <Text style={styles.receiversLabel}>選択中のメンバー（{receivers.memberIds.length}）</Text>
                      ) : null}
                    </View>
                    {mode === "New" || mode === "EditReserved" ? (
                      <TextButton buttonType={ButtonType.Unprioritized} title="宛先を選択" onPress={openSelectReceiversModal} />
                    ) : null}
                  </View>
                </View>
                {(mode === "Edit" || mode === "EditReserved") && (contactId != null || reservedId != null) ? (
                  <>
                    <View style={styles.attachments}>
                      <ItemLabel label="ファイル添付" />
                    </View>
                    <AttachmentViewer
                      organizationId={organizationId}
                      contactId={contactId || reservedId || ""}
                      attachmentFileNames={attachmentFileNames || []}
                    />
                  </>
                ) : null}
                {mode === "New" ? (
                  <>
                    {isLargeScreen ? (
                      <View style={[isSmallScreen ? { display: "flex", flexDirection: "column" } : styles.attachmentsLarge]}>
                        <View
                          style={[
                            isSmallScreen ? styles.attachments : { display: "flex", flexDirection: "column", width: 150 },
                          ]}
                        >
                          <ItemLabel label="ファイル添付" />
                          <View>
                            <Text style={isSmallScreen ? styles.attachmentsInfoLabel : styles.attachmentsInfoLabelLarge}>
                              {attachmentsCount}/{maxFileCount} {attachmentsSize}MB/{maxTotalFileSizeInMB}MB
                            </Text>
                          </View>
                        </View>
                        <View style={[isSmallScreen ? styles.attachmentsSelection : styles.attachmentsSelectionLarge]}>
                          <View style={[styles.attachmentItems]}>
                            {attachmentItemProps.map((props, index) => (
                              <AttachmentItem {...props} key={index} />
                            ))}
                          </View>
                          <View
                            style={[
                              isSmallScreen
                                ? styles.selectAttachmentButtonContainer
                                : styles.selectAttachmentButtonContainerLarger,
                            ]}
                          >
                            <TextButton
                              buttonType={ButtonType.Unprioritized}
                              title="ファイルを選択する"
                              onPress={openAttachmentsModal}
                            />
                          </View>
                        </View>
                      </View>
                    ) : (
                      <>
                        <View style={styles.attachments}>
                          <ItemLabel label="ファイル添付" />
                          <View style={styles.attachmentsInfo}>
                            <Text style={styles.attachmentsInfoLabel}>
                              {attachmentsCount}/{maxFileCount} {attachmentsSize}MB/{maxTotalFileSizeInMB}MB
                            </Text>
                          </View>
                        </View>
                        <View style={styles.attachmentsSelection}>
                          <View style={styles.attachmentItems}>
                            {attachmentItemProps.map((props, index) => (
                              <AttachmentItem {...props} key={index} />
                            ))}
                          </View>
                          <View style={styles.selectAttachmentButtonContainer}>
                            <TextButton
                              buttonType={ButtonType.Unprioritized}
                              title="ファイルを選択する"
                              onPress={openAttachmentsModal}
                            />
                          </View>
                        </View>
                      </>
                    )}
                  </>
                ) : null}
                <View style={styles.title}>
                  <ItemLabel
                    label="タイトル"
                    required
                    RightComponent={<InputLengthCounter text={title} maxLength={50} unit={"字以内"} />}
                  />
                  <View style={styles.titleTextInput}>
                    <CustomTextInput value={title} onChangeText={onTitleChange} placeholder="入力してください" maxLength={50} />
                  </View>
                </View>
                <View style={styles.body}>
                  <ItemLabel
                    label="本文"
                    required
                    RightComponent={<InputLengthCounter text={body} maxLength={4900} unit={"字以内"} />}
                  />
                  <View style={styles.bodyTextInput}>
                    <MultilineTextInput
                      value={body}
                      onChangeText={onBodyChange}
                      placeholder="入力してください"
                      maxLength={4900}
                      style={{ height: 240 }}
                    />
                  </View>
                </View>
                {isContactTypeSurvey ? (
                  <View style={styles.questionsEditorContainer}>
                    <QuestionsEditor
                      questions={questions || []}
                      onQuestionsChange={onQuestionsChange}
                      readOnly={mode === "Edit"}
                    />
                  </View>
                ) : null}
                {isContactTypeScheduleAdjustment ? (
                  <>
                    {!isLargeScreen ? (
                      <View style={styles.sa}>
                        <View style={styles.labelContainer}>
                          <ItemLabel label="候補日時" required />
                        </View>
                        <View style={styles.eventDateCandidates}>
                          {eventDateCandidates?.map((candidate, index) => (
                            <View style={styles.eventDateCandidate} key={`edc${index}`}>
                              <Text style={styles.eventDateCandidateLabel}>{formatCandidate(candidate)}</Text>
                            </View>
                          ))}
                        </View>
                        {mode === "New" || mode === "EditReserved" ? (
                          <TextButton
                            buttonType={ButtonType.Unprioritized}
                            title="候補日時を選択する"
                            onPress={gotoSelectEventDateCandidates}
                          />
                        ) : null}
                      </View>
                    ) : (
                      <View style={styles.sa}>
                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                          <View style={styles.labelContainer}>
                            <ItemLabel label="候補日時" required />
                          </View>
                          {mode === "New" || mode === "EditReserved" ? (
                            <TextButton
                              style={{ marginBottom: 12 }}
                              buttonType={ButtonType.Unprioritized}
                              title="候補日時を選択する"
                              onPress={gotoSelectEventDateCandidates}
                            />
                          ) : null}
                        </View>
                        <View style={styles.eventDateCandidates}>
                          {eventDateCandidates?.map((candidate, index) => (
                            <View style={styles.eventDateCandidate} key={`edc${index}`}>
                              <Text style={styles.eventDateCandidateLabel}>{formatCandidate(candidate)}</Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    )}
                  </>
                ) : null}
                <View style={styles.options}>
                  <ItemLabel label="オプション" />
                  <View style={styles.checkboxContainer}>
                    <Checkbox
                      isChecked={isAddLeadersSentHistory}
                      onPress={toggleIsAddLeadersSentHistory}
                      isDisabled={mode === "Edit"}
                    />
                    <Text style={[styles.checkboxLabel, mode === "Edit" && styles.disabledLabel]}>
                      他の代表者の送信履歴に加える
                    </Text>
                  </View>
                  {isContactTypeAttendance || isContactTypeSurvey || isContactTypeScheduleAdjustment ? (
                    <>
                      <View style={styles.checkboxContainer}>
                        <Checkbox isChecked={isPublishAnswers} onPress={toggleIsPublishAnswers} isDisabled={mode === "Edit"} />
                        <Text style={[styles.checkboxLabel, mode === "Edit" && styles.disabledLabel]}>
                          回答一覧を一般メンバーに公開する
                        </Text>
                      </View>
                      <View style={[styles.datePicker, styles.lastDatePicker]}>
                        <CustomDateTimePicker
                          value={deadlineDate}
                          onChange={handleDeadlineDateChange}
                          title="回答期限を設定する"
                          isDisabled={mode === "Edit"}
                          minuteInterval={1}
                          min={new Date()}
                          errorMessage={invalidDeadlineDateError}
                        />
                      </View>
                      <View style={[styles.datePicker, styles.lastDatePicker]}>
                        <CustomDateTimePicker
                          value={reminderDate}
                          onChange={handleReminderDateChange}
                          title="未回答者に催促する日時を設定"
                          isDisabled={mode === "Edit"}
                          minuteInterval={15}
                          min={new Date()}
                          errorMessage={invalidReminderDateError}
                        />
                      </View>
                    </>
                  ) : null}
                  <View style={[styles.datePicker, styles.lastDatePicker]}>
                    {mode === "New" || mode === "EditReserved" ? (
                      <>
                        <CustomDateTimePicker
                          value={reservationDate}
                          onChange={(next) => onReservationDateChange?.(next)}
                          title="送信予約をする"
                          minuteInterval={15}
                          min={new Date()}
                          errorMessage={invalidReservationDateError}
                        />
                      </>
                    ) : null}
                  </View>
                </View>
              </View>
              {!isLargeScreen ? (
                <View style={[styles.actions, { paddingBottom: bottom + dp }]}>
                  <TextButton
                    buttonType={ButtonType.Primary}
                    title={primaryLabel}
                    onPress={primaryAction}
                    disabled={isPrimaryDisabled}
                    isLoading={isPrimaryLoading}
                  />
                </View>
              ) : (
                <View
                  style={[
                    styles.actions,
                    { paddingBottom: bottom + dp },
                    { width: "100%", display: "flex", alignItems: "center" },
                  ]}
                >
                  <View style={[{ width: 300 }]}>
                    <TextButton
                      buttonType={ButtonType.Primary}
                      title={primaryLabel}
                      onPress={primaryAction}
                      disabled={isPrimaryDisabled}
                      isLoading={isPrimaryLoading}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
        <Modal visible={isOpenAttachmentsModal} transparent={true} onRequestClose={closeAttachmentsModalOnly}>
          <View style={styles.attachmentsModalContainer}>
            <View style={styles.attachmentsModalInner}>
              <Button onPress={closeAttachmentsModalOnly} style={styles.closeModalButton}>
                <RemoveIcon />
              </Button>
              <View style={styles.modalActions}>
                <View style={styles.modalActionButtonContainer}>
                  <TextButton buttonType={ButtonType.Unprioritized} title="写真ライブラリから追加" onPress={openImagePicker} />
                </View>
                <View style={styles.modalActionButtonContainer}>
                  <TextButton buttonType={ButtonType.Unprioritized} title="ファイルから追加" onPress={openDocumentPicker} />
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </>
    )
  }
)

export type AttachmentItemProps = {
  teamId: string
  mailId: string
  index: number
  fileName: string
  sizeInB: number
  onRemove: (index: number) => void
  onUploaderChange: (resourceUnit: ResourceUnit, uploader: () => Promise<Result<undefined, { message: string }>>) => void
} & (
  | {
      type: "image"
      localImage: LocalImage
    }
  | {
      type: "document"
      localDocument: LocalDocument
    }
)

const AttachmentItem = memo<AttachmentItemProps>(({ teamId, mailId, index, fileName, onRemove, onUploaderChange, ...rest }) => {
  const localImage = rest.type === "image" ? rest.localImage : undefined
  const localDocument = rest.type === "document" ? rest.localDocument : undefined
  const isLargeScreen = useCheckPCScreen()

  const { localResource } = useResource({
    init: true,
    type: "attachmentFile",
    teamId,
    mailId,
    index,
    fileName,
    defaultLocalImage: localImage,
    defaultLocalDocument: localDocument,
    onUploaderChange,
  })

  useResource({
    init: true,
    type: "attachmentThumbnail",
    teamId,
    mailId,
    index,
    fileName,
    defaultLocalImage: localImage,
    onUploaderChange,
  })
  if (localResource == null) return <></>

  return (
    <View style={styles.attachmentsItemContainer}>
      <View style={styles.attachmentItem}>
        {localImage ? (
          <Image resizeMode="cover" source={{ uri: localImage?.uri }} style={styles.attachmentThumbnailContainer} />
        ) : (
          <View style={styles.attachmentThumbnailContainer}>
            <DocumentIcon />
          </View>
        )}

        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 1 }}>
          <Text numberOfLines={1} ellipsizeMode="clip" style={[styles.attachmentLabel, { maxWidth: "75%", minWidth: "15%" }]}>
            {localImage ? localImage?.fileName : localDocument?.fileName}
          </Text>
          <Text style={[styles.attachmentLabel, isLargeScreen && { maxWidth: 60 }, { marginRight: 10 }]}>
            {localImage ? getSizeStr(localImage?.sizeInB ?? 0) : getSizeStr(localDocument?.sizeInB ?? 0)}
          </Text>
        </View>
      </View>
      <Button onPress={() => onRemove(index)} style={styles.removeAttachmentButton}>
        <RemoveIcon />
      </Button>
    </View>
  )
})

const styles = StyleSheet.create({
  organizationContainer: {
    paddingTop: dp,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  organizationName: {
    marginLeft: 8,
    fontSize: 15,
    color: Colors.greyshBrown,
    fontWeight: "bold",
  },
  container: {
    paddingTop: dp,
  },
  containerLarge: {
    width: 600,
  },
  template: {
    paddingHorizontal: dp,
    backgroundColor: Colors.white,
    paddingVertical: 20,
  },
  templateLarge: {
    paddingHorizontal: dp,
    backgroundColor: Colors.white3,
    paddingVertical: 20,
  },
  templateRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  templateLabel: {
    paddingLeft: 8,
  },
  templatePicker: {
    paddingTop: dp,
  },
  contactType: {
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  contactTypePicker: {
    paddingTop: dp,
  },
  to: {
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  toPickerLarge: {
    paddingTop: ddp,
    paddingHorizontal: dp,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  toPicker: {
    paddingTop: 12,
  },
  label: {
    fontSize: 16,
  },
  attachments: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  attachmentsLarge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  attachmentsInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
  attachmentsInfoLabel: {
    fontSize: 14,
  },
  attachmentsInfoLabelLarge: {
    fontSize: 14,
    textAlign: "center",
  },
  attachmentsSelection: {
    paddingTop: dp,
  },
  attachmentsSelectionLarge: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    flex: 1,
    paddingLeft: 190,
  },
  attachmentsModalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  attachmentsModalInner: {
    width: 300,
    borderRadius: 16,
    backgroundColor: "#fff",
    padding: 16,
  },
  attachmentsItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 4,
  },
  attachmentItems: {
    paddingBottom: 4,
    paddingLeft: dp,
    width: "100%",
  },
  selectAttachmentButtonContainer: {
    paddingHorizontal: dp,
  },
  selectAttachmentButtonContainerLarger: {
    width: 200,
  },
  attachmentItem: {
    height: 50,
    backgroundColor: Colors.reddishGrey,
    borderRadius: 10,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    minWidth: 146,
  },
  attachmentThumbnailContainer: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2caa0",
  },
  removeAttachmentButton: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentLabel: {
    fontSize: 15,
    color: Colors.greyshBrown,
    marginLeft: 16,
  },
  title: {
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  titleTextInput: {
    paddingTop: dp,
  },
  body: {
    paddingTop: ddp,
    paddingBottom: dp,
    paddingHorizontal: dp,
  },
  bodyTextInput: {
    paddingTop: dp,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#B4B4B4",
  },
  place: {
    paddingTop: ddp,
    paddingBottom: dp,
    paddingHorizontal: dp,
  },
  placeTextInput: {
    paddingTop: dp,
  },
  options: {
    paddingTop: dp,
    paddingHorizontal: dp,
    backgroundColor: Colors.white3,
  },
  checkboxContainer: {
    paddingTop: dp,
    flexDirection: "row",
    alignItems: "center",
  },
  checkboxLabel: {
    paddingLeft: 8,
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  calendarIcon: {
    paddingLeft: 8,
  },
  datePicker: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: dp,
  },
  lastDatePicker: {
    paddingBottom: dp,
  },
  eventDate: {
    paddingTop: dp,
    paddingHorizontal: dp,
  },
  datePickerRangeText: {
    paddingHorizontal: 16,
  },
  textInputPaddingTop: {
    paddingTop: dp,
  },
  labelPaddingTop: {
    paddingTop: ddp,
  },
  answer: {
    paddingTop: dp,
  },
  answerLabel: {
    paddingTop: dp,
  },
  answerInput: {
    flexDirection: "row",
    paddingTop: dp,
  },
  addAnswer: {
    paddingTop: ddp,
  },
  answerValue: {
    flex: 1,
  },
  sa: {
    backgroundColor: Colors.white3,
    padding: dp,
  },
  saLabel: {
    paddingBottom: dp,
  },
  event: { paddingHorizontal: dp },
  eventLabel: {
    paddingTop: dp,
  },
  eventPicker: {
    paddingVertical: dp,
  },
  eventAllDay: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: dp,
  },
  radio: {
    paddingTop: 8,
  },
  receiversLabelContainer: {
    paddingBottom: 12,
  },
  receiversLabel: {
    color: Colors.orange,
    fontWeight: "bold",
    paddingLeft: 16,
    paddingVertical: 2,
  },
  closeModalButton: {
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  modalActions: {
    paddingTop: 12,
  },
  modalActionButtonContainer: {
    paddingVertical: 4,
  },
  actions: {
    paddingHorizontal: 16,
    paddingTop: dp,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  topAction: {
    marginBottom: 16,
  },
  questionsEditorContainer: {
    paddingVertical: dp,
    backgroundColor: Colors.beige,
  },
  labelContainer: {},
  eventDateCandidates: {
    paddingVertical: dp,
  },
  eventDateCandidate: {
    backgroundColor: Colors.white3,
    padding: dp,
    marginVertical: 4,
    borderRadius: 10,
    shadowColor: Colors.lightTan,
    shadowOpacity: 0.3,
    shadowRadius: 15,
  },
  eventDateCandidateLabel: {
    fontWeight: "bold",
  },
  disabledLabel: {
    color: Colors.warmGrey,
  },
  readOnlyValue: {
    fontSize: 16,
    paddingLeft: dp,
  },
})
